import { useNavigate } from "react-router-dom";
import "./register.css";

const MailSentPopup = ({ text, checkModalType }) => {
  const navigate = useNavigate();
  if (checkModalType) {
    setTimeout(() => {
      navigate("/login");
    }, 3000);
  }
  return (
    <div className='forget-password-section'>
      <div className='forget-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='forget-form'>
                <p>{text}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MailSentPopup;
