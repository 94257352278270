// import { useContext } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
// import { toast } from 'react-hot-toast';
import { useLocation, NavLink, Link } from 'react-router-dom';
import logo from '../../assets/images/header/logo-new.png';
// import UserContext from '../../context/userContext';

import './header.css';

const Header = () => {
  const location = useLocation();
  // const navigate = useNavigate();
  // const { setUser, setToken } = useContext(UserContext);
  // let token = null;
  // token = localStorage.getItem('atHomes@token')
  //   ? JSON.parse(localStorage.getItem('atHomes@token'))
  //   : null;

  // const handleLogOut = () => {
  //   localStorage.removeItem('atHomes@token');
  //   localStorage.removeItem('atHomes@userId');
  //   setUser(null);
  //   setToken({});
  //   toast.success('Logout Successfully');
  //   navigate('/login');
  // };

  return (
    <div className='header'>
      <Navbar expand='lg'>
        <div className='container'>
          <Link to='/' className='logo-head'>
            <img src={logo} alt='' />
            {/* <span>Gamingclub.io</span> */}
          </Link>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ms-auto'>
              <NavLink
                to='/'
                className={location.pathname === '/' ? 'nav-active' : ''}>
                Home
              </NavLink>
              <NavLink
                to='/about'
                className={location.pathname === '/about' ? 'nav-active' : ''}>
                About
              </NavLink>
              <NavLink
                to='/deposit'
                className={
                  location.pathname === '/deposit' ? 'nav-active' : ''
                }>
                Deposit
              </NavLink>
              {/* <NavLink
                to='/redeem'
                className={location.pathname === '/redeem' ? 'nav-active' : ''}>
                Redeem
              </NavLink> */}
              <Nav.Link href='https://m.me/gamingclub2.io' target='_blank'>
                Contacts
              </Nav.Link>
              {/* {!token || !user ? (
                <>
                  <NavLink
                    to='/register'
                    className={location.pathname === "/register" ? "nav-active" : ""}>
                    Register
                  </NavLink>
                  <NavLink
                    to='/login'
                    className={location.pathname === "/login" ? "nav-active" : ""}>
                    Login
                  </NavLink>
                </>
              ) : (
                <>
                  <div
                    className={"nav-active"}
                    onClick={handleLogOut}
                    style={{ cursor: "pointer", fontSize: "bold" }}>
                    Logout
                  </div>
                  <NavLink
                    to='/profile'
                    className={location.pathname === "/profile" ? "nav-active" : ""}>
                    Profile
                  </NavLink>
                </>
              )} */}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </div>
  );
};

export default Header;
