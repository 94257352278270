import bannerImg from "../../assets/images/herobanner/herobanner-cover.jpg";

const HeroBanner = () => {
  return (
    <div className="hero-banner">
      <img src={bannerImg} alt="" />
    </div>
  );
};

export default HeroBanner;
