import React from "react";
import Layout from "../layout/layout";

import "./legal.css";

const RefundPolicy = () => {
  return (
    <Layout>
      <div className="refund-page privacy-page">
        <div className="container">
          <div className="legal-wrapper">
            <div className="legal-heading">
              <h2>Refund Policy</h2>
            </div>
            <div className="legal-desc">
              <p>
                You can request a refund for game credits purchase on Gamingclub.io. Maybe your Phone doesn’t meet the hardware requirements;
                maybe you bought a game by mistake; You must not have spent the
                credits we gave you.
              </p>
              <p>
                Please request the refund within two weeks of the payment
                completion by contacting us.
              </p>
              <p>
                You will be issued a full refund of your purchase within two
                days of approval. You will receive the refund through the same
                payment method you used to make the purchase. Refunds could take
                up to two one week or longer to arrive back on your payment
                card.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RefundPolicy;
