import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import chaticon from '../../assets/images/chat/chat-icon.svg';
import SupportChat from '../supportchat/chat';
import './footer.css';

const Footer = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClick = (e) => {
    const tokenData = localStorage.getItem('atHomes@token');
    // let checkTokenData = false;
    // if (tokenData) {
    //   const tokenParsed = tokenData || '';
    //   // const { token, expires } = tokenParsed?.access;
    //   // if (token && new Date() < new Date(expires)) {
    //   //   checkTokenData = true;
    //   // }
    // }
    if (tokenData) {
      setShow(e);
    } else {
      navigate('/login');
      toast.error(
        'You must login into your account first in order to use customer support chat.'
      );
    }
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShow(false);
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  const location = useLocation();
  return (
    <div className='footer'>
      <div className='container'>
        <div className='footer-wrapper'>
          {/* <div className="footer-left">
            <div className="footer-about">
              <h4>About Us</h4>
              <p>701 Tillery Street Suite 12 Austin, TX, US 78702</p>
            </div>
          </div> */}
          <div className='footer-right'>
            {/* <div className="footer-logo">
              <img src={logo} alt="" />
            </div> */}
            <div className='footer-nav'>
              <ul>
                <li>
                  <Link
                    to='/'
                    className={location.pathname === '/' ? 'nav-active' : ''}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to='/privacy'
                    className={
                      location.pathname === '/privacy' ? 'nav-active' : ''
                    }>
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to='/refund-policy'
                    className={
                      location.pathname === '/refund-policy' ? 'nav-active' : ''
                    }>
                    Refund Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to='/terms'
                    className={
                      location.pathname === '/terms' ? 'nav-active' : ''
                    }>
                    Terms and Conditions
                  </Link>
                </li>
              </ul>
            </div>
            <div className='social-links'>
              {/* <ul>
                <li>
                  <Link to='/'>
                    <i className='lab la-facebook-f'></i>
                  </Link>
                </li>
                <li>
                  <Link to='/'>
                    <i className='lab la-twitter'></i>
                  </Link>
                </li>
                <li>
                  <Link to='/'>
                    <i className='lab la-twitch'></i>
                  </Link>
                </li>
                <li>
                  <Link to='/'>
                    <i className='lab la-instagram'></i>
                  </Link>
                </li>
                <li>
                  <Link to='/'>
                    <i className='lab la-telegram'></i>
                  </Link>
                </li>
              </ul> */}
            </div>
            <div className='support-chat-wrapper' ref={wrapperRef}>
              <div className='support-icon'>
                <span
                  role='presentation'
                  onClick={() => handleClick(!show)}
                  id='liveChat'>
                  <img src={chaticon} alt='chat' />
                </span>
              </div>

              {show && <SupportChat show={show} handleClick={handleClick} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
