// import gameBanner from "../../assets/images/gameBanner/image.png";
// import logo from "../../assets/images/header/logo.png";

import "./joinus.css";

const JoinUs = () => {
  return (
    <div className="join-us-section">
      <div className="joinus-wrapper">
        <div className="joinus-right">
          <div className="joinus-content">
            <h3>The Premier Destination For 90+ Games You Love! Open 24/7!</h3>
            <h3>Become Our Member and Enjoy Your Fantastic Daily Promotions From Us!</h3>
            <p>
              Just follow and like our page and get a chance to receive credits
              to play the games you love 💖
            </p>
            <a href="https://m.me/gamingclub2.io" target="_blank" className="cta-btn-link" rel="noreferrer">Join The Club</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinUs;
