import { useEffect, useState } from "react";
import Footer from "../footer";
import Header from "../header";
// import Chat from "../chat/chat";

const Layout = ({ children }) => {
  const [chatmenu, setChatMenu] = useState(true);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="main-wrapper wrapper-hide">
      <div className="wrapper">
        <Header chatmenu={chatmenu} setChatMenu={setChatMenu} />
        <div className="content">{children}</div>
        <Footer />
      </div>
      {/* <div className={`chat-wrapper ${chatmenu ? `` : `chat-hide`}`} > 
            <Chat chatmenu={chatmenu} setChatMenu={setChatMenu}/>
        </div> */}
    </div>
  );
};

export default Layout;
