import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { forgetPassword } from "../../utils/Api";
import { checkEmail } from "../../validationSchema/authSchema";
import "./forget.css";

const Forget = ({ handleModal }) => {
  const [loader, setLoader] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(checkEmail) });

  const handleForget = async (value) => {
    console.log("here is value--> ", value);
    setLoader(true);
    let res = await forgetPassword(value);
    setLoader(false);
    if (res?.status === 200) {
      let msg = res?.data?.msg ? res?.data?.msg : "please check your email";
      toast.success(msg);
    } else {
      let msg = res?.data?.message ? res?.data?.message : "somthing went wrong";
      toast.error(msg);
    }
    // setIsLoading(true);
    // const res = await verifyVerificationCode(value);
    // setIsLoading(false);
    // if (res.code === 200) {
    //   toast.success("Code Verified");
    //   localStorage.setItem("resetPassEmail", resetPassEmailValue);
    //   setTimeout(() => {
    //     navigate(`/reset-password`);
    //   }, 1000);
    // } else {
    //   toast.error(res.message);
    // }
  };

  return (
    <div className='forget-password-section'>
      <div className='forget-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='forget-form'>
                <h1>
                  Forget <span>Password?</span>
                </h1>
                <p>Enter your email to reset your password</p>
                <div className='forget-box'>
                  <Form onSubmit={handleSubmit(handleForget)}>
                    <Form.Group className='form-group'>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type='email'
                        placeholder='Enter your email'
                        name='email'
                        className={errors.email ? "error-field" : ""}
                        {...register("email")}
                      />
                      {!!errors?.email && <p className='error-text'>{errors.email.message}</p>}
                    </Form.Group>
                    <div className='login-button'>
                      <Button className='l-btn' type='submit'>
                        {loader ? <Spinner animation='border' /> : "Continue"}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forget;
