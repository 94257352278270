import Layout from '../layout/layout';
import { Link } from 'react-router-dom';
import arrowIcon from '../../assets/images/about/arrow.svg';
import appCash from '../../assets/images/about/appCash.svg';
import bitCoin from '../../assets/images/about/bitCoin.svg';
import YouTube from 'react-youtube';
import './deposit.css';

const Deposit = () => {
  const opts = {
    height: '300',
    width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <Layout>
      <div className='deposit-page'>
        <div className='container'>
          <div className='deposit-wrapper'>
            <div className='deposit-grid'>
              <Link to='/deposit-bitcoin'>
                <div className='deposit-card'>
                  <div className='card-cntnt'>
                    <img src={bitCoin} alt='' />
                    <h5>Instant Bitcoin</h5>
                  </div>
                  <div className='card-icn'>
                    <img src={arrowIcon} alt='' />
                  </div>
                </div>
              </Link>

              <a
                href='https://cash.app/$CourtneyFTA'
                target='_blank'
                rel='noopener noreferrer'>
                <div className='deposit-card'>
                  <div className='card-cntnt'>
                    <img src={appCash} alt='' />
                    <h5>Cash app ($5 minimum)</h5>
                  </div>
                  <div className='card-icn'>
                    <img src={arrowIcon} alt='' />
                  </div>
                </div>
              </a>
            </div>
            <div className='video-tutorial'>
            <h5>Enable Bitcoin Feature in Cashapp</h5>
            <YouTube
              videoId='N2SRkCdtdm8'
              opts={opts}
              // onReady={(e) => e.target.pauseVideo()}
            />
          </div>

          <div className='video-tutorial d-none'>
            <h5>Don't know how to send and recieve bitcoin?</h5>
            <YouTube
              videoId='PVzXCCQn9M4'
              opts={opts}
              // onReady={(e) => e.target.pauseVideo()}
            />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Deposit;
