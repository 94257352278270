import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, Spinner } from "react-bootstrap";

import cross from "../../assets/images/checkGames/game1.png";
import { createKYC } from "../../utils/Api";

// import {

// } from '../../utils/Api';
import { createKYCSchema } from "../../validationSchema/authSchema";
import toast from "react-hot-toast";
import DatePicker from "react-date-picker";
import { getAge } from "../../utils";

import "./kyc2.css";
import Layout from "../layout/layout";
import { useNavigate } from "react-router-dom";

const KYCForm2 = () => {
  const [frontIdImage, setfrontIdImage] = useState([]);
  const [backIdImage, setbackIdImage] = useState([]);

  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [activeRatioType, setActiveRatioType] = useState("Male");

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(createKYCSchema) });

  const handleImageChange = (e) => {
    const { name } = e.target;
    if (name === "IDimageFront") {
      setfrontIdImage([...e.target.files]);
    }
    if (name === "IDimageBack") {
      setbackIdImage([...e.target.files]);
    }
  };

  const navigate = useNavigate();

  const handleOnChange = (e) => {
    const { value } = e.target;
    setActiveRatioType(value);
  };

  const saveData = async (value) => {
    const formData = new FormData();
    let payload = { ...value };
    if (getAge(dateOfBirthValue) < 18) {
      setError("birthDate", {
        message: "Age must be greater than or equal to 18",
      });
      return;
    }
    if (frontIdImage.length !== 1) {
      setError("IDimageFront", {
        message: "Please uplaod front image of ID",
      });
      return;
    }

    if (backIdImage.length !== 1) {
      setError("IDimageBack", {
        message: "Please uplaod back image of ID",
      });
      return;
    }
    payload.birthDate = dateOfBirthValue;
    payload.gender = activeRatioType;
    formData.append("IDimageFront", frontIdImage[0]);
    formData.append("IDimageBack", backIdImage[0]);
    formData.append("formValues", JSON.stringify(payload));
    setLoading(true);
    const res = await createKYC(formData);
    setLoading(false);
    if (res.status === 201) {
      setSuccessMsg("Uploaded succesfully.");
      setTimeout(() => {
        // openCloseKYCForm(true);
        setSuccessMsg("");
        reset();
        setfrontIdImage([]);
        setbackIdImage([]);
        toast.success("kyc has been successfully submitted ");
        navigate("/");
      }, 1500);
    } else {
      if (res?.code === 409) {
        toast.error(res.message);
      } else {
        toast.error("Unable to uplaod data. Please try again");
      }
    }
  };

  const handleRemoveImage = (index, imgCheck, prevCheck) => {
    if (imgCheck) {
      if (!prevCheck) {
        const copyBannerImg = [...frontIdImage];
        copyBannerImg.splice(index, 1);
        setfrontIdImage(copyBannerImg);
      } else {
        // copyPreviewyBannerImg.splice(index, 1);
      }
    }
  };

  // const closePopup = () => {
  //   openCloseKYCForm();
  // };

  const [dateOfBirthValue, setDateOfBirthvalue] = useState(new Date());
  const onDateChange = (e) => {
    setDateOfBirthvalue(e);
  };

  return (
    <Layout>
      <div className='new-game-wrapper'>
        <Form onSubmit={handleSubmit(saveData)}>
          <div className='game-request-heading'>
            <h2>Add KYC</h2>
          </div>
          <div className='brand-input'>
            <Form.Group className='form-group'>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type='text'
                name='firstName'
                autoComplete='off'
                className={errors.firstName ? "error-field" : ""}
                {...register("firstName")}
              />
              {errors?.firstName ? <p className='error-text'>{errors?.firstName?.message}</p> : ""}
            </Form.Group>
            <Form.Group className='form-group'>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type='text'
                name='lastName'
                autoComplete='off'
                className={errors.lastName ? "error-field" : ""}
                {...register("lastName")}
              />
              {errors?.lastName ? <p className='error-text'>{errors?.lastName?.message}</p> : ""}
            </Form.Group>
          </div>
          <div className='brand-input'>
            <div className='select-banner-area'>
              <p>Gender </p>
              <div className='select-banner-option'>
                <Form.Group
                  className={`form-group ${
                    activeRatioType === "Male" ? "active" : ""
                  } deposit-cash-app`}
                  htmlFor='Male'>
                  <Form.Check
                    label='Male'
                    name='bannerRatio'
                    type='radio'
                    id='Male'
                    value='Male'
                    defaultChecked={activeRatioType === "Male" ? true : false}
                    onChange={handleOnChange}
                  />
                </Form.Group>
                <Form.Group
                  className={`form-group ${
                    activeRatioType === "Female" ? "active" : ""
                  } deposit-cash-app`}
                  htmlFor='Female'>
                  <Form.Check
                    label='Female'
                    name='bannerRatio'
                    type='radio'
                    id='Female'
                    value='Female'
                    defaultChecked={activeRatioType === "Female" ? true : false}
                    onChange={handleOnChange}
                  />
                </Form.Group>
              </div>
            </div>
            <div className='select-banner-area'>
              <p>Date Of Birth </p>
              <div className='select-banner-option'>
                <DatePicker clearIcon={null} onChange={onDateChange} value={dateOfBirthValue} />
                {errors?.birthDate ? (
                  <p className='error-text'>{errors?.birthDate?.message}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <Form.Group className='form-group'>
            <Form.Label>Address</Form.Label>
            <Form.Control
              type='text'
              name='address'
              autoComplete='off'
              className={errors.address ? "error-field" : ""}
              {...register("address")}
            />
            {errors?.address ? <p className='error-text'>{errors?.address?.message}</p> : ""}
          </Form.Group>
          <Form.Group className='form-group'>
            <Form.Label>Street Adress</Form.Label>
            <Form.Control
              type='text'
              name='streetAdress'
              autoComplete='off'
              className={errors.streetAdress ? "error-field" : ""}
              {...register("streetAdress")}
            />
            {errors?.streetAdress ? (
              <p className='error-text'>{errors?.streetAdress?.message}</p>
            ) : (
              ""
            )}
          </Form.Group>
          <Form.Group className='form-group'>
            <Form.Label>City</Form.Label>
            <Form.Control
              type='text'
              name='city'
              autoComplete='off'
              className={errors.city ? "error-field" : ""}
              {...register("city")}
            />
            {errors?.city ? <p className='error-text'>{errors?.city?.message}</p> : ""}
          </Form.Group>
          <Form.Group className='form-group'>
            <Form.Label>State</Form.Label>
            <Form.Control
              type='text'
              name='state'
              autoComplete='off'
              className={errors.state ? "error-field" : ""}
              {...register("state")}
            />
            {errors?.state ? <p className='error-text'>{errors?.state?.message}</p> : ""}
          </Form.Group>
          <Form.Group className='form-group'>
            <Form.Label>Country</Form.Label>
            <Form.Control
              type='text'
              name='country'
              autoComplete='off'
              className={errors.country ? "error-field" : ""}
              {...register("country")}
            />
            {errors?.country ? <p className='error-text'>{errors?.country?.message}</p> : ""}
          </Form.Group>
          <div className='upload-game-images'>
            <div className='upload-logo-thumnail'>
              <Form.Group className='form-group'>
                <Form.Label>Upload Front ID</Form.Label>
                <div className='upload-game-thumnail'>
                  <Form.Control
                    type='file'
                    id='IDimageFront'
                    name='IDimageFront'
                    accept='.png, .jpg, .jpeg'
                    onChange={handleImageChange}
                  />
                  <Form.Label htmlFor='IDimageFront'>
                    <div className='no-image-area'>
                      {frontIdImage.length > 0 ? (
                        <>
                          {" "}
                          {frontIdImage.length > 0 && (
                            <>
                              <img
                                src={cross}
                                alt='cross'
                                className='crossImg'
                                onClick={() => handleRemoveImage(0, false, false)}
                              />
                              <img
                                src={window.URL.createObjectURL(frontIdImage[0])}
                                alt='logo-img'
                              />
                            </>
                          )}
                          <div></div>
                        </>
                      ) : (
                        <h6>
                          Drag & Drop or <span> Upload </span> the Image.
                        </h6>
                      )}
                    </div>
                  </Form.Label>
                </div>
                {errors?.IDimageFront ? (
                  <p className='error-text'>{errors?.IDimageFront?.message}</p>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group className='form-group'>
                <Form.Label>Upload Back ID</Form.Label>
                <div className='upload-game-thumnail'>
                  <Form.Control
                    type='file'
                    id='IDimageBack'
                    name='IDimageBack'
                    accept='.png, .jpg, .jpeg'
                    onChange={handleImageChange}
                  />
                  <Form.Label htmlFor='IDimageBack'>
                    <div className='no-image-area'>
                      {backIdImage.length > 0 ? (
                        <>
                          {" "}
                          {backIdImage.length > 0 && (
                            <>
                              <img
                                src={cross}
                                alt='cross'
                                className='crossImg'
                                onClick={() => handleRemoveImage(0, false, false)}
                              />
                              <img
                                src={window.URL.createObjectURL(backIdImage[0])}
                                alt='logo-img'
                              />
                            </>
                          )}
                          <div></div>
                        </>
                      ) : (
                        <h6>
                          Drag & Drop or <span> Upload </span> the Image.
                        </h6>
                      )}
                    </div>
                  </Form.Label>
                </div>
                {errors?.IDimageBack ? (
                  <p className='error-text'>{errors?.IDimageBack?.message}</p>
                ) : (
                  ""
                )}
                {successMsg && <p className='text-success'>{successMsg}</p>}
              </Form.Group>
            </div>
          </div>

          <div className='popup-footer-btn faq-footer'>
            {/* <Button className='popup-black' onClick={closePopup}>
              Cancel
            </Button> */}
            <Button type='submit' className='popup-red'>
              {!loading ? "Save" : <Spinner animation='border' />}
            </Button>
          </div>
        </Form>
      </div>
    </Layout>
  );
};
export default KYCForm2;
