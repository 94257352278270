// import CheckGames from "../checkgames/checkGames";
// import GameProvider from "../gameProvider/gameProvider";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
// import UserContext from "../../context/userContext";
import { verifyEmail } from "../../utils/Api";
import HeroBanner from "../heroBanner/heroBanner";
import JoinUs from "../joinUs/joinUs";
import Layout from "../layout/layout";
import VerifyEmail from "../login/verifyEmail";
import MailSentPopup from "../register/MailSentPopup";
// import Service from "../service/service";
// import Subscribe from "../subscribe/subscribe";

import "./home.css";

const Home = () => {
  console.log("i am Home");
  // const { user } = useContext(UserContext);
  const urlParams = new URLSearchParams(window.location.search);
  const tokenFromParams = urlParams.get("token");
  const modalFromParams = urlParams.get("modal");
  const [show, setShow] = useState(false);
  const checkVerifyModalShow = modalFromParams && tokenFromParams ? true : false;
  const navigate = useNavigate();

  //for kyc model
  // const [openKYCModal, setOpenKYCModal] = useState(false);
  // const { user } = useContext(UserContext);
  // const openCloseKYCForm = () => {
  //   setOpenKYCModal(!openKYCModal);
  // };

  const handleModal = (e) => {
    setShow(e);
  };

  useEffect(() => {
    const verifyUserByMail = async (token) => {
      const res = await verifyEmail(token);
      console.log("hii this is res1 ", res);
      console.log("hii this is res1 ", res.code);
      if (res.code === 200) {
        toast.success("user has been sucessfully verified please login ");
        setShow(modalFromParams);
        setTimeout(function () {
          navigate("/login");
        }, 1500);
      } else {
        setShow("verify-email");
      }
    };

    if (checkVerifyModalShow) {
      verifyUserByMail(tokenFromParams);
    }
    return () => {};
    // if (!user?.kycCompleted) {
    //   openCloseKYCForm();
    // }
  }, [checkVerifyModalShow, modalFromParams, navigate, tokenFromParams]);

  return (
    <Layout>
      <div className='home'>
        <HeroBanner />
        <div className='container'>
          <JoinUs />
          {/* <Service /> */}
          {/* <CheckGames /> */}
          {/* <Subscribe /> */}
          {/* <GameProvider /> */}
        </div>
        <Modal
          centered
          className='login-popup'
          show={show === "verifying"}
          onHide={() => handleModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MailSentPopup
              handleModal={handleModal}
              text={
                checkVerifyModalShow
                  ? "Your account has been verified"
                  : "Verification link has been send to your mail"
              }
              checkModalType={checkVerifyModalShow}
            />
          </Modal.Body>
        </Modal>
        <Modal
          centered
          className='login-popup'
          show={show === "verify-email"}
          onHide={() => handleModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <VerifyEmail handleModal={handleModal} />
          </Modal.Body>
        </Modal>
      </div>
    </Layout>
  );
};

export default Home;
