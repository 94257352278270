const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const addMinutes = (date, minutes) => {
  return new Date(date.getTime() + minutes * 60000);
};

const formatAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const getformatDate = () => {
  const d = new Date();
  const month = monthNames[d.getMonth()];
  const today = addMinutes(new Date(), -30);
  return month + " " + today.getDate() + ", " + today.getFullYear() + " " + formatAMPM(today);
};
