import { v4 as uuidv4 } from 'uuid';

const getUuId = () => {
  if (sessionStorage.getItem('tempUid')) {
    return sessionStorage.getItem('tempUid');
  } else {
    const tempUid = uuidv4();
    sessionStorage.setItem('tempUid', tempUid);
    return tempUid;
  }
};

export default getUuId;
