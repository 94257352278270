import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import './assets/css/line-awesome.min.css';
import logo from './assets/images/header/logo-new.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/home/home';
import About from './components/about/about';
import Deposit from './components/deposit/deposit';
// import Redeem from './components/redeem/redeem';
import Privacy from './components/legal-pages/privacy';
import RefundPolicy from './components/legal-pages/refundPolicy';
import Terms from './components/legal-pages/terms';
import './responsive.css';
import DepositBitcoin from './components/deposit/depositBitcoin';
// import Register from './components/register/register';
// import Login from './components/login/login';
import Forget from './components/forget/forget';
import Reset from './components/forget/reset';
import VerifyEmail from './components/login/verifyEmail';
// import { getUserById } from './utils/Api';
// import ProtectedRoute from "./ProtectedRoute";
import UserContext from './context/userContext';
import Profile from './components/profile/profile';
// import UserRoute from './components/userRoutes/userRoutes';
import KYCForm2 from './components/kyc2/kyc2';
import Chat from './components/supportchat/chat';
import getUuId from './utils/getUserId';

function App() {
  console.log('i am APP');
  const [, /*token*/ setTokens] = useState(
    localStorage.getItem('atHomes@token')
      ? JSON.parse(localStorage.getItem('atHomes@token'))
      : null
  );

  const [isLoading, setIsLoading] = useState(true);
  // const isLoading = false;
  const [user, setUser] = useState(null);

  useEffect(() => {
    getUuId();
    setIsLoading(false);
  }, []);

  // const getUserByIdApi = async () => {
  //   if (localStorage.getItem('atHomes@token')) {
  //     setIsLoading(true);
  //     let res = await getUserById();
  //     setIsLoading(false);
  //     if (res?.status === 200) {
  //       setUser(res?.data);
  //     } else {
  //       let msg = res?.data?.message
  //         ? res?.data?.message
  //         : 'somthing went wrong';
  //       toast.error(msg);
  //       window.location.href = window.location.origin + '/login';
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (token) {
  //     getUserByIdApi();
  //   }
  //   return () => {};
  // }, [token]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='App'>
          <UserContext.Provider value={{ user, setTokens, setUser }}>
            <Toaster />
            <Router>
              <Routes>
                <Route exact path='/' element={<Home />} />
                <Route exact path='/about' element={<About />} />
                <Route exact path='/privacy' element={<Privacy />} />
                <Route exact path='/refund-policy' element={<RefundPolicy />} />
                <Route exact path='/terms' element={<Terms />} />
                {/* <Route exact path='/register' element={<Register />} />
                <Route exact path='/login' element={<Login />} /> */}
                <Route exact path='/forget' element={<Forget />} />
                <Route exact path='/reset' element={<Reset />} />
                <Route exact path='/verify-email' element={<VerifyEmail />} />
                {/* <Route exact path='/' element={<UserRoute />}> */}
                <Route exact path='/chat' element={<Chat />} />
                <Route exact path='/profile' element={<Profile />} />
                <Route
                  exact
                  path='/deposit-bitcoin'
                  element={<DepositBitcoin />}
                />
                {/* <Route exact path='/redeem' element={<Redeem />} /> */}
                <Route exact path='/deposit' element={<Deposit />} />
                <Route exact path='/kyc' element={<KYCForm2 />} />
                {/* </Route> */}
              </Routes>
            </Router>
            {/* {openKYCModal && (
              // <KYCForm openCloseKYCForm={openCloseKYCForm} openKYCModal={openKYCModal} />
            )} */}
          </UserContext.Provider>
        </div>
      )}
    </>
  );
}

export default App;

const Loader = () => {
  return (
    <div className='loader'>
      <div className='crash-logo'>
        <div className='animated-logo'>
          <img src={logo} alt='' />
        </div>
      </div>
    </div>
  );
};
