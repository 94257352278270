import axios from 'axios';
import { transactionInstance, userInstance } from '../config/axios';
import config from './../config/config';
import getUuId from './getUserId';
const serverUrl = config.server;

export const createAccount = async (values) => {
  try {
    await transactionInstance().post('createAdmin', values);
  } catch (error) {
    console.log('error in create account>>', error);
  }
};

export const deposit = async (value) => {
  try {
    const response = await transactionInstance().post('addDeposit', value);
    console.log('depositRes>>', response);
  } catch (error) {
    console.log('error in deposit>>', error);
  }
};

export const withdraw = async (values) => {
  try {
    const response = await userInstance().post(
      'users/withdrawPayments',
      values
    );
    console.log('withdraw response is ==> ', response.data);

    return response.data;
  } catch (error) {
    console.log('error in withdraw>>', error);
    return error?.response?.data;
  }
};

export const checkStatus = async (userId, transactionId) => {
  let data = { userId, transactionId };
  try {
    const response = await userInstance().post(
      'users/checkwithdrawPaymentStatus',
      data
    );
    console.log('withdraw response is ==> ', response.data);

    return response.data;
  } catch (error) {
    console.log('error in withdraw>>', error);
    return error?.response?.data;
  }
};

export const closeAccount = async (value) => {
  try {
    // await axios.post(`${serverUrl}/api/closeAccount`, value);
    // await axios.post(`${serverUrl}/v1/transaction/closeAccount`, value);
    await transactionInstance().post('closeAccount', value);
  } catch (error) {
    console.log('error in close account>>', error);
  }
};

export const getAccount = async (login, password, code) => {
  try {
    await axios.get(
      `${serverUrl}/api/balance?login=${login}&password=${password}&code=${code}`
    );
  } catch (error) {
    console.log('error in get account>>', error);
  }
};

export const createDepositRequest = async (data) => {
  try {
    console.info('IN CREATE DEPOSIT', data);
    const response = await userInstance().post('users/createDeposit', {
      ...data,
      userId: getUuId(),
    });
    console.info('depositRes>>', response);
    return response.data;
  } catch (error) {
    console.info('error in deposit>>', error);
    return error?.response?.data;
  }
};

export const registerUser = async (data) => {
  try {
    console.info('creating user');
    const response = await userInstance().post(`auth/register`, data);
    console.info('register user>>', response);
    return response;
  } catch (error) {
    console.info('register error>>', error?.response?.data);
    return error?.response?.data;
  }
};

export const loginUser = async (data) => {
  try {
    console.info('loging user');
    const response = await userInstance().post(`auth/login`, data);
    console.info('log in user>>', response);
    return response?.data;
  } catch (error) {
    console.info('error in deposit>>', error);
    return error?.response?.data;
  }
};

export const forgetPassword = async (data) => {
  try {
    console.info('forgetting user');
    const response = await userInstance().post(`auth/forgot-password`, data);
    console.info('log in user>>', response);
    return response;
  } catch (error) {
    console.info('error in deposit>>', error);
    return error?.response;
  }
};

export const resetPassword = async (values, token) => {
  try {
    console.info('resetting user');
    const response = await userInstance().post(
      `auth/reset-password?token=${token}`,
      values
    );
    console.info('log in user>>', response);
    return response;
  } catch (error) {
    console.info('error in deposit>>', error);
    return error?.response;
  }
};

export const verifyEmail = async (token) => {
  try {
    console.info('verifying email');
    const response = await userInstance().post(
      `auth/verify-email?token=${token}`
    );
    console.info('verified user>>', response?.data);
    return response?.data;
  } catch (error) {
    console.info('email verification failed');
    return error?.response;
  }
};

export const sendVerificationEmail = async (values) => {
  try {
    const res = await userInstance().post(
      'auth/send-verification-email',
      values
    );
    return res;
  } catch (error) {
    console.log('Error in singup api =>', error);
    return error.response.data;
  }
};

export const getUserById = async (id) => {
  try {
    const res = await userInstance().get(`users`);
    console.log('this is res---> ', res);
    console.log(res?.data);
    return res;
  } catch (error) {
    console.log('Error in singup api =>', error);
    return error.response;
  }
};

export const uploadImage = async (formData) => {
  try {
    const res = await userInstance().post('users/upload-image', formData);
    console.log('this is image response---> ', res);
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const updateUser = async (values) => {
  try {
    const res = await userInstance().post(`users/update`, values);
    console.log('here is update user res ', res);
    return res;
  } catch (error) {
    console.log('here is update user error.response ', error.response);
    return error.response;
  }
};

export const createKYC = async (requestData) => {
  try {
    const res = await userInstance().post(`users/kyc`, requestData);
    console.log('get KYC data----->', res);
    return res;
  } catch (error) {
    console.log('Error in KYC brands api =>', error);
    return error.response.data;
  }
};

//third party api
export const getPrices = async () => {
  const url = 'https://blockchain.info/ticker';
  try {
    let response = await axios.get(url);
    return response?.data?.USD;
  } catch (error) {
    console.error(error);
  }
};
