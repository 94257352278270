import React from "react";
import { Link } from "react-router-dom";
import Layout from "../layout/layout";

import "./legal.css";

import "./legal.css";

const Terms = () => {
  return (
    <Layout>
      <div className="terms-page privacy-page">
        <div className="container">
          <div className="legal-wrapper">
            <div className="legal-heading">
              <h2>Terms and Conditions</h2>
            </div>

            <div className="legal-content">
              <div className="legal-warn">
                <h4>
                  No purchase necessary. Skill-based. Must be 21 to enter. Not
                  available to residents of California, Washington, South
                  Dakota, Florida, or Michigan.
                </h4>
              </div>
              <div className="legal-title">
                <h5>1. ACCEPTANCE OF TERMS</h5>
              </div>
              <div className="legal-desc">
                <p>
                  The phrase “Gamingclub.io” refers to the Gamingclub.io website{" "}
                  <Link to="/">Gamingclub.io</Link> (“Website”), any Gamingclub.io (River Sweepstakes) software application that is
                  installed anywhere on your mobile device or computer system,
                  and a system of portals, online applications, and social media
                  applications employed to enhance your Internet experience.
                  This page, together with the documents referred to herein,
                  outlines the Terms and Conditions of Use (“Terms of Use” or
                  “Terms”) on which you may use Gamingclub.io. Please read these
                  Terms of Use carefully before using Gamingclub.io. These Terms
                  may have changed since your last visit. If you do not agree to
                  the Terms of Use you must refrain from using Gamingclub.io. If
                  you use this service you will be bound by these terms and
                  conditions. If you check the box for accepting the Terms of
                  Service during the registration process, you agree to be bound
                  by these Terms and Conditions of Use as well as the Game Rules
                  & Regulations and Privacy Policy.
                </p>
                <p>
                  <strong>
                    THE RIVER SWEEPSTAKES (“SWEEPSTAKES”) IS AVAILABLE TO
                    INDIVIDUALS WHO ARE LEGAL RESIDENTS OF THE UNITED STATES.
                    USERS WHO RESIDE IN THE STATE OF WASHINGTON, CALIFORNIA,
                    SOUTH DAKOTA, FLORIDA, OR MICHIGAN ARE NOT PERMITTED TO MAKE
                    PURCHASES. YOU MUST BE AT LEAST EIGHTEEN (21) YEARS OLD TO
                    COLLECT A SWEEPSTAKES PRIZE. THIS SWEEPSTAKE IS VOID IN ANY
                    JURISDICTION WHERE PROHIBITED BY LAW. IT IS YOUR
                    RESPONSIBILITY TO ENSURE THAT YOUR PARTICIPATION IS LEGAL IN
                    YOUR JURISDICTION.
                  </strong>
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>2. SCOPE OF SERVICE</h5>
              </div>
              <div className="legal-desc">
                <p>
                  Gamingclub.io players shall be referred to in the singular as
                  “User” or “you” or “Participant” and in the plural as “Users”
                  or “Participants”.
                </p>
                <p>
                  You are responsible for obtaining any mobile device or
                  computer and Internet service necessary to access Gamingclub.io and for paying any fees for the service and equipment
                  you select. You are responsible for ensuring that all persons
                  who access Gamingclub.io through your mobile device or
                  computer are aware of all the Rules and Regulations and Terms
                  and Conditions of Use, and comply with them.
                </p>
                <p>
                  You promise that you own the email account you use for
                  registration for an account and that all the information you
                  provide to us is and shall remain true, accurate and complete
                  at all times. We reserve the right to suspend or terminate any
                  or all of your accounts if any information provided during the
                  registration process or thereafter proves to be false,
                  inaccurate, not current or incomplete.
                </p>
                <p>
                  You agree that you shall take all steps necessary to protect
                  your log-in details and keep them secret. You agree that you
                  shall not give your log-in details to anyone else or allow
                  anyone else to use your log in details or account.
                </p>
                <p>
                  You accept full responsibility if you fail to keep your login
                  details secret or if you share your log-in details or access
                  to Gamingclub.io with someone else.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>3. INFORMATION ABOUT Gamingclub.io</h5>
              </div>
              <div className="legal-desc">
                <p>Gamingclub.io is owned and operated by Glacierstakes LLC</p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>4. ACCESSING Gamingclub.io</h5>
              </div>
              <div className="legal-desc">
                <p>
                  Access to Gamingclub.io is permitted at the discretion of Gamingclub.io. Gamingclub.io may be changed or withdrawn at
                  the sole discretion of Gamingclub.io Inc. Gamingclub.io Inc
                  will not be liable if Gamingclub.io is unavailable for any
                  reason and at any time.
                </p>
              </div>
              <div className="legal-points">
                <ol>
                  <li>
                    Gamingclub.io Inc may, at any time, restrict access to all
                    or some parts of Gamingclub.io to Users.
                  </li>
                  <li>
                    If Gamingclub.io Inc suspects any fraudulent behavior, Gamingclub.io reserves the right to suspend or terminate
                    any or all of your accounts and suspend or terminate any or
                    all of your accounts ability to win some or all of the
                    prizes, and withhold or revoke the awarding of any prizes. A
                    verified email account is required for cash prize
                    redemption. One email account per user. Multiple users may
                    not share the same email account.
                  </li>
                  <li>
                    You may establish only one Gamingclub.io account per person.
                    In the event Gamingclub.io Inc discovers that you have
                    opened more than one account per person, in addition to any
                    other rights that Gamingclub.io Inc may have, Gamingclub.io
                    Inc reserves the right to suspend or terminate any or all of
                    your accounts and suspend or terminate any or all of your
                    accounts ability to win some or all of the prizes, and
                    withhold or revoke the awarding of any prizes.
                  </li>
                  <li>
                    You cannot use multiple devices to access Gamingclub.io
                    simultaneously. Starting a game session on a device will
                    terminate any existing game sessions associated with the
                    same account on other devices.
                  </li>
                  <li>
                    Use of Gamingclub.io may not be legal to use in certain
                    jurisdictions. The fact that Gamingclub.io is accessible in
                    a jurisdiction, or appears in the official language of any
                    such jurisdiction shall not be construed as a representation
                    or warranty with respect to the legality of you to use Gamingclub.io in that jurisdiction.
                  </li>
                  <li>
                    When using Gamingclub.io, you must comply with the
                    provisions of acceptable conduct as set out in these Terms
                    of Use.
                  </li>
                </ol>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>5. RESTRICTIONS AND LIMITATIONS</h5>
              </div>
              <div className="legal-desc">
                <p>
                  You cannot purchase Gamingclub.io credits if you live in the
                  State of Washington, California, Florida, or Michigan. Any
                  purchases found to be made by residents of the State of
                  Washington California or Michigan will result in your account
                  being deactivated and premium Gamingclub.io will be forfeited.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>6. YOUR Gamingclub.io ACCOUNT</h5>
              </div>
              <div className="legal-desc">
                <p>
                  You are only allowed to have one Gamingclub.io account. If you
                  open more than one account, it is your responsibility to
                  advise customer support by email at{" "}
                  <a href="mailto:support@Gamingclub.io.us">
                    support@Gamingclub.io.us
                  </a>{" "}
                  so that the additional account(s) can be closed. If we notice
                  that you have multiple accounts, we may deactivate all
                  accounts and a single account may be reactivated at the sole
                  discretion of Gamingclub.io Inc.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>7. PURCHASES</h5>
              </div>
              <div className="legal-desc">
                <p>
                  Any purchases that you make should only be made with credit or
                  debit cards in your name. If the name on your profile account
                  differs from the name on the card(s) that you use, your Gamingclub.io account may be temporarily deactivated and you will
                  be required to contact customer service and provide supporting
                  justification for the use of different cards.
                </p>
                <p>
                  Gamingclub.io Inc does not extend credit for any reason
                  whatsoever.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>8. ACCOUNT DEACTIVATION</h5>
              </div>
              <div className="legal-desc">
                <p>
                  Gamingclub.io Inc may terminate any User’s access to Gamingclub.io in its sole discretion, at any time and for any reason,
                  with or without prior notice. It is Gamingclub.io Inc’s policy
                  to terminate Users who violate these Terms and Conditions of
                  Use as deemed appropriate in Gamingclub.io Inc’s sole
                  discretion. Gamingclub.io Inc also reserves the right to
                  terminate accounts with offensive Display Names. You agree
                  that Gamingclub.io is not liable to you or any third party for
                  any termination of your access to Gamingclub.io.
                </p>
                <p>
                  If you process any chargebacks, your account will be
                  immediately suspended, your standard and premium balances may
                  be voided and you will not be eligible to redeem premium Gamingclub.io for cash.
                </p>
                <p>
                  If you are found to have multiple Gamingclub.io accounts, all
                  accounts may be permanently deactivated.
                </p>
                <p>
                  If you communicate with us in an offensive, defamatory,
                  abusive, or threatening manner, we reserve the right to
                  deactivate your account.
                </p>
                <p>
                  If your account is flagged for fraudulent activity, your
                  account will be immediately deactivated and the redeemable
                  value of Premium Gamingclub.io will be forfeited.
                </p>
                <p>
                  If you would like to close your Gamingclub.io account, please
                  submit a request to support@Gamingclub.io.us with the subject
                  <strong> ACCOUNT DEACTIVATION.</strong> Your account will be
                  deactivated within 24 hours from acknowledgment of your
                  request. You may be able to reactivate your Gamingclub.io
                  account again by submitting a formal request by email. If your
                  request is approved, your account will be reactivated within
                  72 hours.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>9. VERIFICATION AND PERSONAL INFORMATION</h5>
              </div>
              <div className="legal-desc">
                <p>
                  Gamingclub.io Inc uses a third-party agency to perform
                  verification checks using the personal information that you
                  provide in your account profile. You will not be permitted to
                  collect any sweepstakes prize winnings until you have
                  successfully completed the verification process. The
                  information that you provide in your account profile must be
                  identical to the information on your government-issued
                  identification.
                </p>
                <p>
                  Gamingclub.io processes information about you in accordance
                  with the Gamingclub.io privacy policy. By using Gamingclub.io
                  you consent to such processing. To facilitate the collection
                  of prizes, you agree to provide current, accurate and complete
                  personal information and it is your responsibility to contact
                  us to update that information if it becomes out of date in
                  order to remain eligible for collecting prizes.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>10. PAYMENTS</h5>
              </div>
              <div className="legal-desc">
                <p>
                  Gamingclub.io Inc uses third-party services to provide
                  payments. Payments are issued via Bitcoin and Zelle as well as
                  CashApp refunds. Cash App is not always available to send
                  payments as a prize therefore we would fall back on Zelle,
                  bitcoin, or a bank transfer.
                </p>
                <p>
                  Payment of prize winnings may be delayed if there are any
                  issues with verifying your personal identification From time
                  to time, the bank transfer functionality may be temporarily
                  unavailable while we perform enhancements, upgrades, or
                  general maintenance. You may experience some delays in
                  receiving prize winnings to your bank account when the bank
                  transfer functionality is unavailable.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>11. GAMING LIMITS</h5>
              </div>
              <div className="legal-desc">
                <p>
                  You can take a break from game play by voluntarily
                  self-excluding yourself from Gamingclub.io for a defined
                  period of time. Once you set your self-exclusion time period,
                  you will immediately be logged out of Gamingclub.io and you
                  will not be able to login until your set time has expired.
                </p>
                <p>
                  If you wish to permanently close your account, you can submit
                  a request to support@Gamingclub.io.us and your account will be
                  deactivated within 24 hours.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>12. Gamingclub.io CONTENT</h5>
              </div>
              <div className="legal-desc">
                <p>
                  Users have a personal, non-exclusive, non-transferable, right
                  to use and access the Content of Gamingclub.io subject to
                  these Terms of Use. The term “Content” means all information,
                  images, text, links, data, software, or other material
                  accessible through Gamingclub.io, whether created by Gamingclub.io or provided by another person for display on Gamingclub.io. At times, Gamingclub.io may act as an intermediary
                  between customers and other companies who provide advertising
                  and other content to enhance the user experience. Gamingclub.io may direct customers to websites that promote or sell
                  products and services. Gamingclub.io Inc is not responsible
                  for any issues related to those products and services
                  including but not limited to billing, payments, shipping, the
                  quality of the product or service, or any misrepresentation of
                  the product or service. The Content may contain typographical
                  errors, other inadvertent errors, or inaccuracies. Gamingclub.io reserves the right to make changes to document names
                  and content, specifications or descriptions of products and
                  services, or other information without obligation to issue any
                  notice of such changes. You may view, copy, download, and
                  print Content that is available on Gamingclub.io, subject to
                  the following conditions:
                </p>
              </div>
              <div className="legal-points">
                <ol>
                  <li>
                    The Content may be used solely for your own informational
                    purposes. No part of Gamingclub.io or its Content may be
                    transmitted or reproduced in any form, by any means,
                    mechanical or electronic, including photocopying and
                    recording for any other purpose;
                  </li>
                  <li>The Content may not be modified; and</li>
                  <li>
                    Copyright, trademark, and other proprietary notices may not
                    be removed.
                  </li>
                </ol>
              </div>
              <div className="legal-desc">
                <p>
                  Nothing contained on Gamingclub.io should be construed as
                  granting, by implication, estoppel, or otherwise, any license
                  or right to use Gamingclub.io or any Content displayed on Gamingclub.io, through the use of framing or otherwise, except:
                  <br />
                  (1) as expressly permitted by these Terms of Use; or <br />
                  (2) with Gamingclub.io Inc’s prior written permission or the
                  permission of such third party that may own the trademark or
                  copyright of material displayed on Gamingclub.io.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>13. OFFERS AND SERVICES DESCRIPTIONS</h5>
              </div>
              <div className="legal-desc">
                <p>
                  Any products, service, discount prices, promotions, coupons
                  and bonuses that are displayed on Gamingclub.io by third party
                  companies (“Offer” or “Offers”) for the benefit of Users are
                  available for a designated period of time. Gamingclub.io Inc
                  cannot verify all information provided by third party
                  companies and errors in reporting, gathering, and displaying
                  such information may occur. Gamingclub.io Users understand and
                  agree that Gamingclub.io Inc shall not be liable related to
                  availability or accuracy of any Offers. Any prices listed on
                  Offers are the responsibility of the advertiser and may
                  represent a comparative price estimate and may or may not
                  represent the prevailing price in every geographic area on any
                  particular day. Please note that taxes and other charges may
                  be applicable and applied by the relevant gaming company in
                  connection with any Offer and Gamingclub.io refers you to
                  their Terms and Conditions.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>14. INDEMNIFICATION</h5>
              </div>
              <div className="legal-desc">
                <p>
                  BY USING Gamingclub.io, YOU AGREE TO INDEMNIFY, DEFEND AND
                  HOLD HARMLESS Gamingclub.io INC, AFFILIATES, AND SUBSIDIARY
                  COMPANIES, AGENTS, EMPLOYEES, OFFICERS, DIRECTORS,
                  CONSULTANTS, SUPPLIERS, ADVERTISERS, PROMOTERS, PARTNERS, AND
                  THEIR AGENTS, EMPLOYEES, OFFICERS, AND DIRECTORS FROM ANY AND
                  ALL THIRD PARTY CLAIMS, DAMAGES, LIABILITY AND COSTS.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>15. PROHIBITED CONDUCT</h5>
              </div>
              <div className="legal-desc">
                <p>
                  By accessing Gamingclub.io, you agree to abide by the
                  following standards of conduct. You may only use Gamingclub.io
                  for lawful purposes. You agree that you will not, and will not
                  authorize or facilitate any attempt by another person to use
                  Gamingclub.io:
                </p>
              </div>
              <div className="legal-points">
                <ol>
                  <li>
                    To transmit any Content or use any language (such as a
                    Display Name) that is harmful, unlawful, abusive,
                    threatening, defamatory, harassing, lewd, vulgar, offensive,
                    obscene, pornographic, lascivious, or otherwise
                    objectionable, as determined by Gamingclub.io Inc.
                  </li>
                  <li>
                    To knowingly receive, send, upload, download, use or reuse
                    material that does not comply with Gamingclub.io’ content
                    standards as set out in these Terms of Use or use a name or
                    language that Gamingclub.io Inc, in its sole discretion,
                    deems offensive.
                  </li>
                  <li>
                    To procure or transmit any unauthorized or unsolicited
                    promotional material or advertising or any other forms of
                    similar solicitation (spam) or unlawfully promote offers or
                    services.
                  </li>
                  <li>
                    To solicit, promote, or participate in any multi-level
                    marketing or pyramid schemes.
                  </li>
                  <li>
                    To harm or exploit or attempt to harm children less than 21
                    years of age in any way.
                  </li>
                  <li>
                    Introduce any data, upload or send any material that
                    contains worms, viruses, Trojan horses, time-bombs,
                    keystroke loggers, adware, spyware, or other harmful
                    programs or similar code designed to adversely affect the
                    operation of any computer hardware or software. To obtain
                    unauthorized access to any computer system through Gamingclub.io.
                  </li>
                  <li>
                    To invade the privacy of any person, including but not
                    limited to posting personally identifying or otherwise
                    private information about a person without his or her
                    consent.
                  </li>
                  <li>
                    In any way that breaches any applicable local or
                    international law or regulation.
                  </li>
                  <li>
                    In any way that is unlawful or fraudulent or has any
                    unlawful or fraudulent purpose or effect.
                  </li>
                  <li>
                    To reproduce, duplicate, copy or resell any part of Gamingclub.io in contravention of these Terms of Use.
                  </li>
                  <li>
                    To encourage conduct that would constitute a civil or
                    criminal offense.
                  </li>
                </ol>
              </div>
              <div className="legal-desc">
                <p>
                  You also agree not to access without authority, interfere
                  with, damage or disrupt:
                </p>
              </div>
              <div className="legal-points">
                <ol>
                  <li>any part of Gamingclub.io;</li>
                  <li>any software used in the provision of Gamingclub.io;</li>
                  <li>
                    any network or equipment on which Gamingclub.io is stored;
                    or
                  </li>
                  <li>
                    any network or equipment or software owned or used by any
                    third party.
                  </li>
                </ol>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>16. AGE RESTRICTION</h5>
              </div>
              <div className="legal-desc">
                <p>
                  Gamingclub.io is intended for an adult audience only and is
                  not in any way targeted at youth under age 21. Gamingclub.io
                  does not knowingly market to, solicit or collect information
                  from any person under the age of 21.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>
                  17. INTELLECTUAL PROPERTY RIGHTS17. INTELLECTUAL PROPERTY
                  RIGHTS
                </h5>
              </div>
              <div className="legal-desc">
                <p>
                  Unless otherwise noted, all Content contained on Gamingclub.io
                  is the property of Gamingclub.io Inc, licensors or affiliates
                  and is protected from unauthorized copying and dissemination
                  by copyright laws, trademark laws, international conventions
                  and treaties, and other intellectual property laws. All such
                  rights are reserved. Offer names are trademarks or registered
                  trademarks of their respective owners.
                </p>
                <p>
                  Gamingclub.io Inc does not claim ownership of Content
                  submitted by Users but Users understand and agree that such
                  Content may be made publicly accessible through Gamingclub.io.
                  By submitting such Content, you agree to grant us a worldwide,
                  perpetual, irrevocable, non-exclusive, royalty-free license to
                  distribute, use, reproduce, adapt, modify, create derivative
                  works from, and publicly perform or display such Content. This
                  license shall remain in effect until Gamingclub.io deletes the
                  Content from its systems.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>18. SECURITY</h5>
              </div>
              <div className="legal-desc">
                <p>
                  You are solely responsible for protecting your login, password
                  and profile details from unauthorized use (including
                  preventing the use by persons under the age of 21), and you
                  are solely responsible for all activity that occurs on your
                  account. You agree to notify Gamingclub.io Inc immediately if
                  you believe that your account has been or may be used without
                  your permission so that appropriate action can be taken. Gamingclub.io is not responsible for damages or losses caused
                  by your failure to safeguard your login and/or password.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>19. HACKING OFFENCES & FRAUDULENT ACTIVITY</h5>
              </div>
              <div className="legal-desc">
                <p>
                  You agree to not misuse Gamingclub.io by knowingly introducing
                  worms, viruses, trojans, logic bombs or other material which
                  is technologically harmful or malicious. You agree to not
                  attempt to gain unauthorized access to Gamingclub.io, the
                  server on which Gamingclub.io is stored or any computer,
                  server, or database connected to Gamingclub.io. You agree to
                  not attack Gamingclub.io via a denial-of-service attack or a
                  distributed denial of service attack. Gamingclub.io Inc will
                  not be liable for any damage or loss caused by a distributed
                  denial-of-service attack, viruses or other technologically
                  harmful material that may infect your computer software,
                  hardware, equipment, mobile device, computer programs, data or
                  other proprietary material due to your use of Gamingclub.io or
                  to your downloading of any material posted on or linked to Gamingclub.io.
                </p>
                <p>
                  You agree that you will not participate in any unlawful
                  activity on the Gamingclub.io platform. You agree not to use
                  illicit funds on the Gamingclub.io platform, including the
                  form of money laundering or any other type of financial crime.
                  You agree that you will only use cards that you are authorized
                  to use. You agree not to modify or tamper with any documents
                  that you provide for verification purposes, including
                  identification documents. You agree not to set up multiple
                  accounts for the purpose of exploiting special offers.
                </p>
                <p>
                  Account transactions are continually monitored for fraudulent
                  activity and money laundering. If your account is flagged for
                  the suspicious or improper activity that is in violation of
                  these Terms of Use, your account will be immediately
                  deactivated and Gamingclub.io will not be obligated to refund
                  any purchases or redeem sweepstakes prizes. Furthermore, we
                  will cooperate with all authorities including financial
                  institutions and law enforcement agencies and will provide all
                  necessary information for their investigation.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>20. LIMITATION OF LIABILITY</h5>
              </div>
              <div className="legal-desc">
                <p>
                  The material and Offers displayed on Gamingclub.io is provided
                  without any guarantees, conditions or warranties as to its
                  accuracy. To the extent permitted by law, Gamingclub.io Inc,
                  affiliates, and subsidiary companies and third parties
                  connected to Gamingclub.io hereby expressly exclude:
                </p>
              </div>
              <div className="legal-points">
                <ol>
                  <li>
                    All conditions, warranties and other terms which might
                    otherwise be implied by statute, common law or the law of
                    equity;
                  </li>
                  <li>
                    Any liability for any direct, indirect or consequential
                    damage or loss incurred by any User in connection with Gamingclub.io or in connection with the use, inability to use,
                    or results of the use of Gamingclub.io, any mobile
                    applications or websites linked to it and any materials
                    posted on it, including:
                  </li>
                  <ol>
                    <li>
                      All conditions, warranties and other terms which might
                      otherwise be implied by statute, common law or the law of
                      equity; and
                    </li>
                    <li>
                      Any liability for any direct, indirect or consequential
                      damage or loss incurred by any User in connection with Gamingclub.io or in connection with the use, inability to
                      use, or results of the use of Gamingclub.io, and any
                      Offers or materials posted on Gamingclub.io.
                    </li>
                  </ol>
                </ol>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>
                  21. THIRD-PARTY WEBSITES, APPLICATIONS AND SERVICE PROVIDERS
                </h5>
              </div>
              <div className="legal-desc">
                <p>
                  Gamingclub.io may contain links to third-party websites and
                  mobile applications that are provided to you as a convenience.
                  Any third-party website or mobile application accessed from Gamingclub.io is independent of Gamingclub.io, and Gamingclub.io
                  Inc has no control over the content of such third-party
                  websites or mobile applications. Gamingclub.io Inc is not
                  responsible for the content of any linked third party website
                  or mobile application or for any loss or damage incurred in
                  connection with your use of such third party websites or
                  mobile applications or dealings with the operators of such
                  third-party websites or mobile applications.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>22. LINKING TO Gamingclub.io</h5>
              </div>
              <div className="legal-desc">
                <p>
                  Users may include a link to Gamingclub.io in social media
                  channels provided that it does not suggest association or
                  endorsement on Gamingclub.io Inc’s part. Gamingclub.io must
                  not be listed on any website or mobile app without the
                  approval and consent from Gamingclub.io Inc. Permission to
                  link to Gamingclub.io can be withdrawn at any time, and at the
                  sole discretion of Gamingclub.io Inc, without notice. If you
                  wish to make use of any Gamingclub.io material other than as
                  set out above please contact us by email at
                  support@Gamingclub.io.us
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>23. NO IMPLIED ENDORSEMENTS</h5>
              </div>
              <div className="legal-desc">
                <p>
                  In no event shall any reference to any Offer be construed as
                  an approval or endorsement by Gamingclub.io of that third
                  party or of any offer provided by a third party. Likewise, a
                  link to any third-party website or mobile application does not
                  imply that Gamingclub.io Inc endorses or accepts any
                  responsibility for the content or use of such a website or
                  mobile application. Gamingclub.io does not endorse, warrant,
                  or guarantee any Offer through Gamingclub.io and will not be a
                  party to or in any way monitor any transaction involving any
                  third-party providers of Offers. As with the purchase of an
                  Offer through any medium or in any environment, you are
                  responsible for exercising appropriate caution and judgment.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>24. SEVERABILITY</h5>
              </div>
              <div className="legal-desc">
                <p>
                  If for any reason any provision of the Terms of Use or portion
                  thereof, is found by a court of competent jurisdiction to be
                  unlawful, void, or unenforceable, that part of Terms of Use
                  will be deemed severable and shall not affect the validity and
                  enforceability of the remainder of these Terms of Use which
                  shall continue in full force and effect.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>25. VARIATIONS</h5>
              </div>
              <div className="legal-desc">
                <p>
                  Gamingclub.io may from time to time revise these Terms of Use
                  by amending this page. You are expected to check this page
                  from time to time and take notice of any changes Gamingclub.io
                  made, as they are binding on you. Some of the provisions
                  contained in these Terms of Use may also be superseded by
                  provisions or notices published elsewhere in Gamingclub.io.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>26. ENTIRE AGREEMENT</h5>
              </div>
              <div className="legal-desc">
                <p>
                  YOU ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THESE TERMS
                  OF USE AND AGREE TO BE BOUND BY ITS TERMS. YOU FURTHER AGREE
                  THAT THESE TERMS OF USE ARE THE COMPLETE AND EXCLUSIVE
                  STATEMENT OF THE AGREEMENT BETWEEN YOU AND Gamingclub.io INC,
                  AND SUPERSEDES ANY PROPOSAL OR PRIOR AGREEMENT, WRITTEN OR
                  ORAL, AND ANY OTHER COMMUNICATIONS RELATING TO THE SUBJECT
                  MATTER OF THESE TERMS OF USE WITH THE UNDERSTANDING THAT Gamingclub.io MAY, AT ITS SOLE DISCRETION AND WITHOUT NOTICE,
                  REVISE THESE TERMS AT ANY TIME BY UPDATING THIS PAGE.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>27. FOR ADDITIONAL INFORMATION</h5>
              </div>
              <div className="legal-desc">
                <p>
                  If you have any questions about these Terms of Use, please
                  contact us at support@Gamingclub.io.us
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Terms;
