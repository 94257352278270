import React, { useState } from "react";
import { Button, Form, Spinner, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import { checkEmail } from "../../validationSchema/authSchema";
import toast from "react-hot-toast";
import "./login.css";
import MailSentPopup from "../register/MailSentPopup";
import { sendVerificationEmail } from "../../utils/Api";

const VerifyEmail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(checkEmail) });
  const handleModal = (e) => {
    setShow(e);
  };
  const handleChange = (e) => {
    const { value, name } = e.target;
    console.log({ value, name });
    setValue("email", value);
  };
  const handleVerifyEmail = async (values) => {
    setIsLoading(true);
    const res = await sendVerificationEmail(values);
    setIsLoading(false);
    if (res.status === 204) {
      setTimeout(() => {
        handleModal("verifying");
      }, 1000);
    } else {
      let msg = res.message ? res.message : "somthing went wrong";
      toast.error(msg);
    }
  };
  return (
    <>
      <div className='forget-password-section'>
        <div className='auth-page'>
          <div className='forget-section'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='forget-form'>
                    <h1>You account is not verified.</h1>
                    <p className='auth-para'>Please enter your mail to get verify link.</p>
                    <div className='forget-box'>
                      <Form onSubmit={handleSubmit(handleVerifyEmail)}>
                        <Form.Group className='form-group'>
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type='text'
                            name='email'
                            className={errors.email ? "error-field" : ""}
                            {...register("email")}
                            onChange={(e) => handleChange(e)}
                          />
                          <Button type='submit' className='send-btn'>
                            {isLoading ? <Spinner animation='border' /> : "Send"}
                          </Button>
                          {errors?.email ? (
                            <p className='error-text'>{errors.email.message}</p>
                          ) : (
                            ""
                          )}
                        </Form.Group>
                      </Form>
                      <p className='already'>
                        <Link to='/login' className='forget-link'>
                          Back to Login
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        centered
        className='login-popup'
        show={show === "verifying"}
        onHide={() => handleModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MailSentPopup
            handleModal={handleModal}
            text={"Verification link has been send to your mail"}
            checkModalType={false}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VerifyEmail;
