import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { resetPassword } from "../../utils/Api";
import { ResetPasswordSchema } from "../../validationSchema/authSchema";

const Reset = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const [pass, setPass] = useState("password");
  const [confirmPass, setConfirmPass] = useState("password");
  const [loader, setLoader] = useState(false);
  const handlePass = () => {
    setPass(!pass);
  };
  const handleConfirmPass = () => {
    setConfirmPass(!confirmPass);
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(ResetPasswordSchema) });

  const handleResetPassword = async (values) => {
    setLoader(true);
    let res = await resetPassword(values, token);
    setLoader(false);
    if (res?.status === 200) {
      let msg = res.data.msg ? res.data.msg : "password has been changed";
      toast.success(msg);
    } else {
      let msg = res.data.msg ? res.data.msg : "somthing went wrong";
      toast.error(msg);
    }
  };

  return (
    <div className='forget-password-section'>
      <div className='forget-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='forget-form'>
                <h1>
                  Reset <span>Password</span>
                </h1>
                <p>Enter your new password</p>
                <div className='forget-box'>
                  <Form onSubmit={handleSubmit(handleResetPassword)}>
                    <Form.Group className='form-group'>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type={`${pass ? `password` : `text`}`}
                        name='password'
                        className={errors.password ? "error-field" : ""}
                        {...register("password")}
                      />
                      <span className='input-icon' onClick={handlePass}>
                        <i class={pass ? "las la-eye" : "las la-eye-slash"}></i>
                      </span>
                      {!!errors?.password && (
                        <p className='error-text'>{errors.password.message}</p>
                      )}
                    </Form.Group>
                    <Form.Group className='form-group'>
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type={`${confirmPass ? `password` : `text`}`}
                        name='confirmPassword'
                        className={errors.confirmPassword ? "error-field" : ""}
                        {...register("confirmPassword")}
                      />
                      <span className='input-icon' onClick={handleConfirmPass}>
                        <i class={confirmPass ? "las la-eye" : "las la-eye-slash"}></i>
                      </span>
                      {!!errors?.confirmPassword && (
                        <p className='error-text'>{errors.confirmPassword.message}</p>
                      )}
                    </Form.Group>
                    <div className='login-button'>
                      <Button type='submit' className='l-btn'>
                        {loader ? <Spinner animation='border' /> : "Change Password"}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reset;
