import React, { useEffect } from "react";
import { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import Layout from "../layout/layout";
import userimg from "../../assets/images/header/logo.png";
import "./profile.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { profileUpdate } from "../../validationSchema/authSchema";
import { updateUser, uploadImage } from "../../utils/Api";
import { toast } from "react-hot-toast";
import UserContext from "../../context/userContext";
import { useContext } from "react";

const Profile = () => {
  const [selectedFile, setSelectedFile] = useState();
  const { user, setUser } = useContext(UserContext);
  const [preview, setPreview] = useState(user ? user?.avatar : userimg);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadingLoading, setUploadLoading] = useState(false);
  // const [checkEmailInput, setCheckEmailInput] = useState("");

  console.log("this is preview ", preview);

  const handleUpdate = async (values) => {
    console.log("here is values--->  ", values);
    setIsLoading(true);
    let response = await updateUser(values);
    setIsLoading(false);
    if (response?.status === 200) {
      toast.success("sucessfully updated");
    } else {
      toast.error("something went wrong");
    }
  };

  const handlePicUpload = async (e) => {
    setUploadLoading(true);
    const formData = new FormData();
    if (selectedFile) {
      formData.append("avatar", selectedFile);
      const response = await uploadImage(formData);
      setUploadLoading(false);
      if (response.status === 200) {
        toast.success("Profile image changed");
        setUser(response.data.userData);
      } else {
        toast.error("Image has not been changed");
      }
    }
  };

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(profileUpdate),
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      nickName: user?.nickName,
    },
  });

  useEffect(() => {
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [selectedFile]);

  return (
    <Layout>
      <div className='signup'>
        <div className='user-image'>
          <img src={preview} alt='user' />
          <div className='upload-profile'>
            <input
              type='file'
              id='imageUpload'
              name='file'
              accept='.png, .jpg, .jpeg'
              onChange={onSelectFile}
            />
            <label htmlFor='imageUpload'>
              <i class='las la-camera'></i>
            </label>
          </div>
          <div className='text-center'>
            <Button className='s-btn' onClick={handlePicUpload}>
              {uploadingLoading ? <Spinner animation='border' /> : "Upload"}
            </Button>
          </div>
        </div>
        <div className='register-section'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='register-form'>
                  <h1>here is your details</h1>
                  <p></p>
                  <div className='rigister-tab'>
                    <Form onSubmit={handleSubmit(handleUpdate)}>
                      <Form.Group className='form-group'>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter your first name'
                          name='firstName'
                          autoComplete='off'
                          {...register("firstName")}
                        />
                        {!!errors?.firstName && (
                          <p className='error-text'>{errors.firstName.message}</p>
                        )}
                      </Form.Group>

                      <Form.Group className='form-group'>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter your last name'
                          name='lastName'
                          autoComplete='off'
                          {...register("lastName")}
                        />
                        {!!errors?.lastName && (
                          <p className='error-text'>{errors.lastName.message}</p>
                        )}
                      </Form.Group>

                      <Form.Group className='form-group'>
                        <Form.Label>Nick Name</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter your nic name'
                          name='nickName'
                          autoComplete='off'
                          {...register("nickName")}
                        />
                        {!!errors?.nickName && (
                          <p className='error-text'>{errors.nickName.message}</p>
                        )}
                      </Form.Group>

                      <div className='text-center'>
                        <Button className='s-btn' type='submit'>
                          {isLoading ? <Spinner animation='border' /> : "update Account"}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
