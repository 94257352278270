import axios from 'axios';
import config from './../config/config';
const serverUrl = config.server;

export const userInstance = () => {
  return axios.create({
    baseURL: `${serverUrl}/v1/`,
  });
};

export const transactionInstance = () => {
  return axios.create({
    baseURL: `${serverUrl}/v1/transaction/`,
    headers: {
      Authorization: localStorage.getItem('atHomes@token')
        ? `${'Bearer '}${localStorage.getItem('atHomes@token')}`
        : '',
    },
  });
};
