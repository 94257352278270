// 1 - function related to date and time
function getMonthName(month) {
  const d = new Date();
  d.setMonth(month);
  const monthName = d.toLocaleString("default", { month: "long" });
  return monthName;
}
export const getDate = (d) => {
  const date = new Date(d);
  const day = date.getDate(); // Date of the month: 2 in our example
  let month = date.getMonth(); // Month of the Year: 0-based index, so 1 in our example
  const year = date.getFullYear(); // Year: 2013
  //const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  //const dayofweek = weekday[date.getDay()];
  month = getMonthName(month);
  return `${day} ${month} ${year}`;
};

export const getTime = (date) => {
  let dateChange = date ? new Date(date) : new Date();
  let time = dateChange.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return time;
};

export const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
