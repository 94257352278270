import React from "react";
import Layout from "../layout/layout";
import fishImg from "../../assets/images/about/fish1.png";

import "./about.css";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <Layout>
      <div className="about-page">
        <div className="container">
          <div className="about-wrapper">
            <div className="joinus-content">
              <h3>
                Welcome To <span>Gamingclub.io!</span>
              </h3>
              <h3>
                Here, you can buy credits to play your favorite online games.
              </h3>
              <p>
                We feature more than 90+ games that are fun and engaging,
                including skill-based shooting games like Fish Tables (see
                Galaxy Fishing below). Aim and shoot at the fish and bosses to
                collect points!
              </p>
            </div>
            <div className="about-img">
              <img src={fishImg} alt="" />
            </div>
            <Link to="/deposit">
              <Button className="cta-btn">Deposit Now</Button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default About;
