import React from "react";
import { Link } from "react-router-dom";
import Layout from "../layout/layout";

import "./legal.css";

const Privacy = () => {
  return (
    <Layout>
      <div className="privacy-page">
        <div className="container">
          <div className="legal-wrapper">
            <div className="legal-heading">
              <h2>Privacy Policy</h2>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>1. Overview</h5>
              </div>
              <div className="legal-desc">
                <p>
                  This Privacy Policy describes how we handle your personal
                  information for our Service on the Gamingclub.io websites,
                  tools, and applications. It applies generally to information
                  collected on the <Link to="/">Gamingclub.io</Link> website and
                  content (the “Website”) or through the use of our Service.
                  Capitalized terms used in this Privacy Policy shall have the
                  meaning set forth herein or in the User Agreement posted on
                  the Website.
                </p>
                <p>
                  By accepting the Privacy Policy and the User Agreement, you
                  expressly consent to our collection, storage, use and
                  disclosure of your personal information as described in this
                  Privacy Policy. This Privacy Policy is effective upon
                  acceptance for new Users and is otherwise effective on
                  December 23, 2020.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>2. Types of Data We Collect</h5>
              </div>
              <div className="legal-desc">
                <p>
                  <strong>“Personal Data”</strong> means data that allows
                  someone to identify or contact you, including, for example,
                  your name, address, telephone number, e-mail address, as well
                  as any other non-public information about you that is
                  associated with or linked to any of the foregoing data.{" "}
                  <strong>“Anonymous Data”</strong> means data that is not
                  associated with or linked to your Personal Data; Anonymous
                  Data does not, by itself, permit the identification of
                  individual persons. We collect Personal Data and Anonymous
                  Data, as described below.
                </p>
                <p>
                  You can browse our Website without telling us who you are or
                  revealing any Personal Data about yourself. Once you give us
                  your Personal Data, you are not anonymous to us. If you choose
                  to provide us with Personal Data, you consent to the transfer
                  and storage of that information on our servers located in the
                  United States.
                </p>
              </div>
              <div className="legal-subtitle">
                <h6>We may collect and store the following Personal Data:</h6>
              </div>
              <div className="legal-points">
                <ul>
                  <li>
                    e-mail address, phone number, physical contact information,
                    and sometimes financial information, such as credit card or
                    bank account numbers;
                  </li>
                  <li>
                    transactional information based on your activities on the
                    Website (such as buying, selling, item and content you
                    generate or that relates to your account);
                  </li>
                  <li>
                    shipping, billing and other information you provide to
                    purchase an item;
                  </li>
                  <li>
                    community discussions, chats, dispute resolution,
                    correspondence through our Website, and correspondence sent
                    to us;
                  </li>
                  <li>
                    other information from your interaction with our Website,
                    Service, and content, including device ID, computer and
                    connection information, statistics on page views, traffic to
                    and from the sites, IP address and standard web log
                    information;
                  </li>
                  <li>
                    additional information we ask you to submit to authenticate
                    yourself or if we believe you are violating site policies
                    (for example, we may ask you to send us an ID or bill to
                    verify your address, or to answer additional questions
                    online to help verify your identity or ownership of an item
                    you list);
                  </li>
                  <li>
                    information from other companies, such as demographic and
                    navigation data;
                  </li>
                  <li>
                    if you provide us feedback or contact us via e-mail, we will
                    collect your name and e-mail address, as well as any other
                    content included in the e-mail, in order to send you a
                    reply; and
                  </li>
                  <li>other supplemental information from third parties.</li>
                </ul>
              </div>
              <div className="legal-subtitle">
                <h6>Information Collected via Technology.</h6>
              </div>
              <div className="legal-points">
                <ul>
                  <li>
                    To make our Website and Service more useful to you, our
                    servers (which may be hosted by a third party service
                    provider) collect information from you, including your
                    browser type, operating system, Internet Protocol (IP)
                    address (a number that is automatically assigned to your
                    computer when you use the Internet, which may vary from
                    session to session), domain name, and/or a date/time stamp
                    for your visit.
                  </li>
                  <li>
                    We also use cookies and URL information to gather
                    information regarding the date and time of your visit and
                    the information for which you searched and which you viewed.
                    <strong>“Cookies”</strong> are small pieces of information
                    that a website sends to your computer’s hard drive while you
                    are viewing the Website. We may use both session Cookies
                    (which expire once you close your web browser) and
                    persistent Cookies (which stay on your computer until you
                    delete them) to provide you with a more personal and
                    interactive experience on our Website. Persistent Cookies
                    can be removed by following Internet browser help file
                    directions.
                  </li>
                </ul>
              </div>
              <div className="legal-subtitle">
                <h6>Information Collected from Third Party Companies.</h6>
              </div>
              <div className="legal-desc">
                <p>
                  We may receive Personal and/or Anonymous Data about you from
                  companies that provide our Service by way of a co-branded or
                  private-labeled website or companies or individuals that offer
                  their products on our Website or through our Service. These
                  third party companies and individuals may supply us with
                  Personal Data,. We may add this information to the information
                  we have already collected from you via our Website in order to
                  improve the Service we provide.
                </p>
              </div>
              <div className="legal-subtitle">
                <h6>Registration and sharing Through Third-Party Services.</h6>
              </div>
              <div className="legal-desc">
                <p>
                  We may offer single sign-on services that allow you to use
                  third party login credentials to sign into Service. With your
                  permission, Gamingclub.io may also transfer to Gamingclub.io
                  profile information contained in your third-party profile.
                  Gamingclub.io may also, for your use, enable you to import
                  information about who you are connected to, as well as enable
                  you to share information with those third-party sites. If you
                  wish to discontinue such sharing, you will be able to do so
                  through your preference page.
                </p>
              </div>
              <div className="legal-subtitle">
                <h6>Information You Share on Gamingclub.io.</h6>
              </div>
              <div className="legal-desc">
                <p>
                  Some (mostly optional) information you provide, such as your
                  full name, may be displayed throughout the Website (and so
                  available to the public) and connected to all of your
                  Gamingclub.io activity. Notices sent to other community members
                  about suspicious activity and policy violations on our Website
                  refer to specific items. So if you associate your name with
                  your items, the people to whom you have revealed your name
                  will be able to personally identify your Gamingclub.io items.
                  <br />
                  If you access our Website from a shared computer or a computer
                  in an Internet café, certain information about you may also be
                  visible to other individuals who use the computer after you.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>3. Use of Your Personal Data</h5>
              </div>
              <div className="legal-subtitle">
                <h6>
                  Our primary purpose in collecting Personal Data is to provide
                  you with a safe, smooth, efficient, and customized experience.
                  You agree that we may use your Personal Data to:
                </h6>
              </div>
              <div className="legal-points">
                <ul>
                  <li>
                    facilitate the creation of and secure your account on our
                    network;
                  </li>
                  <li>identify you as a User in the system;</li>
                  <li>
                    send you a welcome e-mail to verify ownership of the e-mail
                    address provided when your account was created;
                  </li>
                  <li>
                    respond to your inquiries related to employment
                    opportunities or other requests;
                  </li>
                  <li>provide the Service and customer support you request;</li>
                  <li>
                    resolve disputes, collect fees, and troubleshoot problems;
                  </li>
                  <li>
                    prevent, detect, and investigate potentially prohibited or
                    illegal activities, and enforce our User Agreement;
                  </li>
                  <li>
                    customize, measure and improve our Service and content;
                  </li>
                  <li>
                    tell you about our Service, service updates, and promotional
                    offers based on your communication preferences;
                  </li>
                  <li>
                    compare information for accuracy, and verify it with third
                    parties; and
                  </li>
                  <li>
                    and other uses as described when we collect the information.
                  </li>
                </ul>
              </div>
              <div className="legal-subtitle">
                <h6>Creation of Anonymous Data.</h6>
              </div>
              <div className="legal-desc">
                <p>
                  We may create Anonymous Data records from Personal Data by
                  excluding information (such as your name) that make the data
                  personally identifiable to you. We use this Anonymous Data to
                  analyze request and usage patterns so that we may enhance the
                  content of our Service and improve site navigation. We reserve
                  the right to use Anonymous Data for any purpose and disclose
                  Anonymous Data to third parties in our sole discretion.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>4. Our Disclosure of Your Personal Data</h5>
              </div>
              <div className="legal-desc">
                <p>
                  We may disclose Personal Data to respond to legal
                  requirements, enforce our policies, respond to claims that a
                  listing or other content violates the rights of others, or
                  protect anyone’s rights, property, or safety. Such information
                  will be disclosed in accordance with applicable laws and
                  regulations.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>5. Using Information from Gamingclub.io</h5>
              </div>
              <div className="legal-desc">
                <p>
                  Gamingclub.io enables you to share personal and financial
                  information to complete transactions and collect payments. We
                  encourage you to disclose your privacy practices and respect
                  the privacy of other Users. We cannot guarantee the privacy or
                  security of your information and therefore we encourage you to
                  evaluate the privacy and security policies of your trading
                  partner before entering into a transaction and choosing to
                  share your information. To help protect your privacy, we allow
                  only limited access to other Users’ contact, shipping and
                  financial information to facilitate your transactions and
                  collect payments. When Users are involved in a transaction,
                  they may have access to each other’s name, User ID, e-mail
                  address and other contact and shipping information. In all
                  cases, you must give other Users a chance to remove themselves
                  from your database and a chance to review what information you
                  have collected about them.
                </p>
              </div>
              <div className="legal-subtitle">
                <h6>You agree to use User information only for:</h6>
              </div>
              <div className="legal-points">
                <ul>
                  <li>
                    Gamingclub.io transaction-related purposes that are not
                    unsolicited commercial messages;
                  </li>
                  <li>
                    using services offered through Gamingclub.io (such as shipping
                    and fraud complaints), or
                  </li>
                  <li>other purposes that a User expressly chooses.</li>
                </ul>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>6. No Spam, Spyware or Spoofing</h5>
              </div>
              <div className="legal-desc">
                <p>
                  We and our Users do not tolerate spam. Make sure to set your
                  Gamingclub.io communication preferences so we communicate to you
                  as you prefer. You are not licensed to add other Gamingclub.io
                  Users, even a User who has purchased an item from you, to your
                  mailing list (e-mail or physical mail) without their express
                  consent. To report Gamingclub.io-related spam or spoof e-mails
                  to Gamingclub.io, please forward the e-mail to
                  support@Gamingclub.io. You may not use our communication tools
                  to send spam or otherwise send content that would violate our
                  User Agreement. We automatically scan and may manually filter
                  messages to check for spam, viruses, phishing attacks and
                  other malicious activity or illegal or prohibited content, but
                  we do not permanently store messages sent through these tools.
                  If you send an e-mail to an e-mail address that is not
                  registered in our community, we do not permanently store that
                  e-mail or use that e-mail address for any marketing purpose.
                  We do not rent or sell these e-mail addresses.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>7. Account Protection</h5>
              </div>
              <div className="legal-desc">
                <p>
                  Your password is the key to your account. Use unique numbers,
                  letters and special characters, and do not disclose your
                  Gamingclub.io password to anyone. If you do share your password
                  or your Personal Data with others, remember that you are
                  responsible for all actions taken in the name of your account.
                  If you lose control of your password, you may lose substantial
                  control over your Personal Data and may be subject to legally
                  binding actions taken on your behalf. Therefore, if your
                  password has been compromised for any reason, you should
                  immediately notify Gamingclub.io and change your password.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>8. Accessing, Reviewing and Changing Your Personal Data</h5>
              </div>
              <div className="legal-desc">
                <p>
                  You may change any of your Personal Data in your account by
                  editing your profile within your account or by sending an
                  e-mail to us at the e-mail address set forth below. You may
                  request deletion of your Personal Data by us, but please note
                  that we may be required to keep this information and not
                  delete it (or to keep this information for a certain time, in
                  which case we will comply with your deletion request only
                  after we have fulfilled such requirements). Upon your request,
                  we will close your account and remove your Personal Data from
                  view as soon as reasonably possible, based on your account
                  activity and in accordance with applicable law. We do retain
                  Personal Data from closed accounts to comply with law, prevent
                  fraud, collect any fees owed, resolve disputes, troubleshoot
                  problems, assist with any investigations, enforce our User
                  Agreement, and take other actions otherwise permitted by law.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>9. Security</h5>
              </div>
              <div className="legal-desc">
                <p>
                  Your information is stored on our servers located in the
                  United States. We use a variety of industry-standard security
                  technologies and procedures to help protect your Personal Data
                  from unauthorized access, use or disclosure. However, as you
                  probably know, third parties may unlawfully intercept or
                  access transmissions or private communications, and other
                  Users may abuse or misuse your Personal Data that they collect
                  from the Website. Therefore, although we work very hard to
                  protect your privacy, we do not promise, and you should not
                  expect, that your Personal Data or private communications will
                  always remain private.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>10. Third Parties</h5>
              </div>
              <div className="legal-desc">
                <p>
                  Except as otherwise expressly included in this Privacy Policy,
                  this document addresses only the use and disclosure of
                  information we collect from you. If you disclose your
                  information to others, whether they are bidders, buyers or
                  sellers on our Website or other sites throughout the Internet,
                  different rules may apply to their use or disclosure of the
                  information you disclose to them. Gamingclub.io does not control
                  the privacy policies of third parties, and you are subject to
                  the privacy policies of those third parties where applicable.
                  We encourage you to ask questions before you disclose your
                  Personal Data to others.
                </p>
              </div>
            </div>

            <div className="legal-content">
              <div className="legal-title">
                <h5>11. Changes to this Privacy Policy</h5>
              </div>
              <div className="legal-desc">
                <p>
                  This Privacy Policy is subject to occasional revision, and if
                  we make any material changes in the way we use your Personal
                  Data, we will notify you by sending you an e-mail to the last
                  e-mail address you provided to us and/or by prominently
                  posting notice of the changes on our Website. Any changes to
                  this Privacy Policy will be effective upon the earlier of
                  thirty (30) calendar days following our dispatch of an e-mail
                  notice to you or thirty (30) calendar days following our
                  posting of notice of the changes on our Website. These changes
                  will be effective immediately for new Users of our Service.
                  Please note that at all times you are responsible for updating
                  your Personal Data to provide us with your most current e-mail
                  address. In the event that the last e-mail address that you
                  have provided us is not valid, or for any reason is not
                  capable of delivering to you the notice described above, our
                  dispatch of the e-mail containing such notice will nonetheless
                  constitute effective notice of the changes described in the
                  notice. In any event, changes to this Privacy Policy may
                  affect our use of Personal Data that you provided us prior to
                  our notification to you of the changes. If you do not wish to
                  permit changes in our use of your Personal Data, you must
                  notify us prior to the effective date of the changes that you
                  wish to deactivate your account with us. Continued use of our
                  Website or Service following notice of such changes shall
                  indicate your acknowledgement of such changes and agreement to
                  be bound by the terms and conditions of such changes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Privacy;
