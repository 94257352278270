import * as yup from "yup";

export const RegisterSchema = yup.object().shape({
  // name: yup
  //   .string()
  //   .required("Name is required")
  //   .matches(
  //     /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
  //     "Name should be alphabetical"
  //   ),
  // nickName:  yup.string().when('nickName', {
  //     is: (value) => value?.length,
  //     then: (rule) => rule.matches(
  //       "^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$",
  //       "Nick Name should be alphanumeric"
  //     ),
  // }),
  firstName: yup.string().required("first name is required"),
  lastName: yup.string().required("last name is required"),
  nickName: yup
    .string()
    .required("Nick name is required")
    .min(6, "Nick name should be atleast 6 Char")
    .matches("^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$", "Nick name shuld be alphanumeric"),
  email: yup.string().email("Please enter valid email").required("Email is required"),
  phoneNumber: yup.string().required("Phone number is required"),
  verifyPassword: yup
    .string()
    .required("Password must match")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  password: yup
    .string()
    .required("Password is required")
    .matches(/^(.{0,16}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/, "Password must be alpa numeric")
    .min(8)
    .max(20),
  agree: yup.bool().oneOf([true], "Terms & Conditions must be checked"),
});

export const LoginSchema = yup
  .object()
  .shape({
    email: yup.string().email("Please enter valid email").required("Email is required"),
    password: yup.string().required("Password is required"),
    remember: yup.boolean().default(false),
  })
  .required();

export const ResetPasswordSchema = yup.object({
  confirmPassword: yup
    .string()
    .required("Password must match")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  password: yup
    .string()
    .required("Password is required")
    .matches(/^(.{0,16}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/, "Password must be alpa numeric"),
});

export const ForgetPasswordSchema = yup.object({
  email: yup.string().email("Please enter valid email").required("Email is required"),
  verificationCode: yup.string().max(6).required("Verification code is required"),
});
export const checkEmail = yup.object({
  email: yup.string().email("Please enter valid email").required("Email is required"),
});

export const ContactsSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().email("Please enter valid email").required("Email is required"),
  message: yup.string().required("Message is required"),
});

export const DistributorSchema = yup.object().shape({
  companyName: yup.string().required("Name is required"),
  phoneNumber: yup
    .number()
    .typeError("That doesn't look like a phone number")
    .positive("A phone number can't start with a minus")
    .integer("A phone number can't include a decimal point")
    .min(8, "Phone number must be greater than 8")
    .required("A phone number is required"),
  numberOfGames: yup
    .number()
    .typeError("That doesn't look like a number")
    .positive("Start with a minus")
    .integer("Can't include a decimal point")
    .max(5, "maximum 5 digits allowed")
    .required("Number of games is required"),
  email: yup.string().email("Please enter valid email").required("Email is required"),
  message: yup.string().required("Message is required"),
});

export const profileUpdate = yup.object().shape(
  {
    firstName: yup.string(),
    lastName: yup.string(),
    nickName: yup
      .string()
      .required("Nick name is required")
      .min(6, "Nick name should be atleast 6 Char")
      .matches("^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$", "Nick name shuld be alphanumeric"),
    // email: yup.string().email("Please enter valid email"),
    // phoneNumber: yup
    //   .string()
    //   .required("Phone number is required")
    //   .min(6, "Phone number must be at least 6 digits"),
    // password: yup
    //   .string()
    //   .nullable()
    //   .notRequired()
    //   .when("password", {
    //     is: (value) => value?.length,
    //     then: (rule) =>
    //       rule
    //         .matches(
    //           /^(.{0,16}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/,
    //           "Password must be alpa numeric"
    //         )
    //         .min(8)
    //         .max(20),
    // }),
    // verifyPassword: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
    // cashTagId: yup.string(),
    // btcAddress: yup.string(),
  }
  // [
  //   // Add Cyclic deps here because when require itself
  //   ["password", "password"],
  // ]
);

export const createKYCSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("First name is required")
    .matches(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/, "First Name should be alphabetical"),
  lastName: yup
    .string()
    .required("Last name is required")
    .matches(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/, "Last Name should be alphabetical"),
  address: yup.string().required("Address is required"),
  streetAdress: yup.string().required("Street Address is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  country: yup.string().required("Country is required"),
  // birthDate: yup.date().required('Birthdate is required'),
  // gender: yup.string().required('Gender is required')
});
