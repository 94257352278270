import React from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Layout from '../layout/layout';
import './deposit.css';
import { createDepositRequest, getPrices } from '../../utils/Api';
import { useState } from 'react';
import { useEffect } from 'react';
import { getformatDate } from '../../utils/useFun';
import { socket } from '../../config/socket';
import { toast } from 'react-hot-toast';
import getUuId from '../../utils/getUserId';
import Swal from 'sweetalert2';

const DepositBitcoin = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [bitcoinQrCode, setBitcoinQrCode] = useState('');
  const [address, setAddress] = useState('');
  const [currBTCPriceUsd, setBtcPrice] = useState('');
  const [date, setDate] = useState('');
  const DepositSchema = yup.object().shape({
    code: yup
      .string()
      .required('Please provide a valid river pin')
      .test(
        'test-pin',
        'Pin cant have more then or less then 8 number',
        function (value) {
          let pattern = /^((\d{2})\W?){5}(\d{2})(?=($|\s*))/;
          return pattern.test(value);
        }
      ),
    // email: yup.string().email().required("Please provide a valid email address"),
    amount: yup.string().required('Please enter amount.').nullable(),
    bounceBack: yup.boolean(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(DepositSchema),
  });

  const onSubmit = async (data) => {
    console.info('data>>', data);
  };

  const handleDeposit = async (value) => {
    console.info('SUBMIT FUNCTION CALLED');
    console.log('this is the value', value);

    setLoader(true);

    const response = await createDepositRequest(value);
    setLoader(false);
    if (response?.message) {
      toast(response?.message);
    }

    // setBitcoinQrCode(response?.data?.qrCode);
    if (response?.status === 'success') {
      let url = `https://image-charts.com/chart?chs=150x150&cht=qr&chl=${response?.address}&choe=UTF-8`;
      setBitcoinQrCode(url);
      setAddress(response?.address);
    }
  };

  const handleCodeChange = (e) => {
    const value = e.target.value;
    if (!isFinite(value.charAt(value.length - 1))) {
      return;
    }

    let str = value
      .split('-')
      .join('')
      .replace(/.{2}(?!$)/g, '$&-');

    setValue('code', str);
  };

  const getPriceAndDate = async () => {
    const btc = await getPrices();
    const getDate = getformatDate();
    setDate(getDate);
    setBtcPrice(btc.buy);
  };

  useEffect(() => {
    getPriceAndDate();
  }, []);

  useEffect(() => {
    socket.on('transactionConfirmed', (data) => {
      console.log('socket deposit data--> ', data);
      Swal.fire({
        title:
          'Your deposit has been confirmed! Thank you for playing with us and good luck!!',
        text: 'PLEASE DO NOT SEND TO THE PREVIOUS BITCOIN ADDRESS AGAIN, WE WILL NOT BE RESPONSIBLE FOR ANY LOSSES.',
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#e69100',
        confirmButtonText: 'close!',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/');
        }
      });
    });
  }, [navigate]);

  // useEffect(() => {
  //   Swal.fire({
  //     title:
  //       'Your deposit has been confirmed! Thank you for playing with us, have fun!!',
  //     text: 'PLEASE DO NOT SEND TO THE PREVIOUS BITCOIN ADDRESS AGAIN, WE WILL NOT BE RESPONSIBLE FOR ANY LOSSES.',
  //     icon: 'success',
  //     showCancelButton: false,
  //     confirmButtonColor: '#e69100',
  //     confirmButtonText: 'close!',
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       navigate('/');
  //     }
  //   });
  // }, [navigate]);

  useEffect(() => {
    const uuID = getUuId();
    socket.emit('join', { userId: uuID });
  }, []);

  return (
    <Layout>
      <div className='deposit-bitcoin deposit-page'>
        <div className='container'>
          {!!bitcoinQrCode ? (
            <div className='deposite-grid'>
              <h2>Scan and send bitcoin on this address</h2>
              <p className='deposit-red'>
                TO MAKE A DEPOSIT AGAIN, FILL OUT THE DEPOSIT FORM TO GET YOUR
                NEW BTC ADDRESS. PLEASE DO NOT SEND TO THE PREVIOUS ONE, WE WILL
                NOT BE RESPONSIBLE FOR ANY LOSSES.
              </p>

              <div className='deposite-box'>
                <span>QR Code</span>
                <img
                  src={bitcoinQrCode}
                  alt='PAY BTC'
                  style={{ height: '20vh' }}
                />
              </div>

              <div className='deposite-box'>
                <span>Deposit Address</span>
                <span>{address}</span>
              </div>

              <div className='deposite-box'>
                <span>Current BTC Price</span>
                <span>{currBTCPriceUsd}USD</span>
              </div>

              <div className='deposite-box'>
                <span>Price Guaranteed Until</span>
                <span>{date}</span>
              </div>

              <div className='deposite-box'>
                <span>Address Tracking Link</span>
                <span>
                  {' '}
                  <a
                    href={`https://www.blockchain.com/explorer/addresses/btc/${address}`}>
                    Tracking Link
                  </a>
                </span>
              </div>
            </div>
          ) : (
            <div className='deposit-bitcoin-wrapper deposit-wrapper'>
              <div className='joinus-content'>
                <h3>Bitcoin Auto Deposit!</h3>
                <p>
                  The minimum to deposit is $6. You can send it from your
                  Cashapp instantly 🙂.
                </p>
                <h4>Payment Information</h4>
              </div>
              <Form
                className={`deposit-form was-validated`}
                onSubmit={handleSubmit(handleDeposit, onSubmit)}>
                {/* </div> */}
                <div className='deposit-form-row1'>
                  <Form.Group className='deposit-formfield'>
                    <Form.Label>PIN</Form.Label>
                    <Form.Control
                      className={errors.code ? 'error' : ''}
                      placeholder='Ex: 11-22-33-44-55-66'
                      type='text'
                      pattern='^((\d{2})\W?){5}(\d{2})(?=($|\s*))'
                      autocomplete='on'
                      required
                      {...register('code', {
                        onChange: handleCodeChange,
                      })}
                    />
                    {errors.code && (
                      <span className='error'>{errors.code.message}</span>
                    )}
                  </Form.Group>
                </div>
                <Form.Group className='deposit-formfield2'>
                  <Form.Label>Amount in USD</Form.Label>
                  <Form.Control
                    className={errors.amount ? 'error' : ''}
                    type='number'
                    min={6}
                    required
                    {...register('amount')}
                  />
                  {errors.amount && (
                    <span className='error'>{errors.amount.message}</span>
                  )}
                </Form.Group>

                <Form.Group className='deposit-formfield2'>
                  <Form.Check
                    type='checkbox'
                    id='bounceBack'
                    required
                    label='I confirm the information provided above is correct, and that I am authorized to deposit credits for the account provided'
                    {...register('bounceBack')}
                  />
                </Form.Group>
                <div className='form-btn'>
                  <Button className='cta-btn' type='submit' disabled={loader}>
                    {loader ? (
                      <Spinner animation='border' role='status' />
                    ) : (
                      'Get Deposit Address'
                    )}
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default DepositBitcoin;
