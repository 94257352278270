import { io } from 'socket.io-client';
import getUuId from '../utils/getUserId';
import config from './../config/config';
// import { server } from "./keys.js";

const server = config.server;
const token = localStorage.getItem('atHomes@token')
  ? localStorage.getItem('atHomes@token')
  : null;
// console.log({ token, server });
const socket = io(server, {
  reconnectionDelayMax: 1000,
  reconnection: true,
  transports: ['websocket', 'xhr-polling'],
  rejectUnauthorized: false,
  query: `token=${token}`,
});

socket.on('connect', () => {
  const uuID = getUuId();
  socket.emit('join', { userId: uuID });
});

socket.on('reconnect', () => {
  if (token) {
    const uuID = getUuId();
    socket.emit('join', { userId: uuID });
  }
});

const tryReconnect = () => {
  setTimeout(() => {
    socket.io.open((err) => {
      if (err) {
        tryReconnect();
      }
    });
  }, 2000);
};

socket.io.on('close', tryReconnect);

export { socket };
